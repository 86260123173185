//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import  i18n  from '@/vueI18n';

const { fields } = UserModel;

export default {
  name: 'app-email-unverified-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loadingEmailConfirmation',
      email: 'auth/currentUserEmail',
      is_screen_xs: 'layout/is_screen_xs',
      loadingVerify: 'auth/loadingVerify',
    }),

    fields() {
      return fields;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.emailUnverified.verify') 
    },
  },

  methods: {
    ...mapActions({
      doSendEmailConfirmation:
        'auth/doSendEmailConfirmation',
      doSignout: 'auth/doSignout',
      doEmailVerified: 'auth/doEmailVerified',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      await this.doSendEmailConfirmation(this.model.email);
    },
    async doVerify() {
      await this.doEmailVerified();
    },
  },
};
